
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/fonoma/home_landing_announced_phone/[[...timestamp]]",
      function () {
        return require("private-next-pages/fonoma/home_landing_announced_phone/[[...timestamp]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/fonoma/home_landing_announced_phone/[[...timestamp]]"])
      });
    }
  